import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "react-tabs/style/react-tabs.css";

function PeriodCalculator() {
  const [lastPeriodDate, setLastPeriodDate] = useState(new Date());
  const [cycleLength, setCycleLength] = useState(28);
  const [fertileStart, setFertileStart] = useState(null);
  const [fertileEnd, setFertileEnd] = useState(null);

  const handleChange = (date) => {
    setLastPeriodDate(date);
  };

  const handleCycleLengthChange = (event) => {
    setCycleLength(parseInt(event.target.value));
  };

  useEffect(() => {
    if (lastPeriodDate) {
      const startDate = new Date(lastPeriodDate);
      startDate.setDate(lastPeriodDate.getDate() + cycleLength - 19);
      setFertileStart(new Date(startDate));
      startDate.setDate(startDate.getDate() + 4);
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 5);
      setFertileEnd(new Date(endDate));
    }
  }, [lastPeriodDate, cycleLength]);

  const calculateFertilePeriod = () => {
    return `${
      fertileStart.getMonth() + 1
    }/${fertileStart.getDate()}/${fertileStart.getFullYear()} - ${
      fertileEnd.getMonth() + 1
    }/${fertileEnd.getDate()}/${fertileEnd.getFullYear()}`;
  };

  const isFertile = (date) => {
    if (fertileStart && fertileEnd) {
      if (date >= fertileStart && date <= fertileEnd) {
        return true;
      }
    }
    return false;
  };

  return (
    <div>
      <h1>Menstrual Cycle Calculator</h1>
      <div>
        <label>마지막 생리일:</label>
        <DatePicker
          showOn="both"
          value={new Date()}
          selected={lastPeriodDate}
          onChange={handleChange}
          onFocus={(e) => {
            e.target.readOnly = true;
            e.target.blur();
          }}
          withPortal
        />
      </div>
      <div>
        <label>생리 주기(일): </label>
        <select value={cycleLength} onChange={handleCycleLengthChange}>
          {[...Array(25)].map((_, i) => (
            <option key={i} value={i + 21}>
              {i + 21}
            </option>
          ))}
        </select>
      </div>
      <div>
        {lastPeriodDate && fertileStart && fertileEnd && (
          <p>Your fertile period is: {calculateFertilePeriod()}.</p>
        )}
      </div>
      <div>
        <h2>Calendar View</h2>
        <Calendar
          view="month"
          activeStartDate={fertileStart}
          tileClassName={({ date, view }) => {
            if (isFertile(date)) {
              let classes = "react-calendar-fertile ";
              if (view === "month") {
                return classes;
              }
            }
            return "";
          }}
        />
      </div>
    </div>
  );
}

export default PeriodCalculator;
